@font-face {
  font-family: Open Sans;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTRsxEYwM7FgeyaSgU71cLG0.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Open Sans;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/uYKcPVoh6c5R0NpdEY5A-Q.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Open Sans;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzBsxEYwM7FgeyaSgU71cLG0.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGMa9awK0IKUjIWABZIchFI8.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGNbE_oMaV8t2eFeISPpzbdE.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro"), local("SourceSansPro-Regular"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Semibold"), local("SourceSansPro-Semibold"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGJ6-ys_j0H4QL65VLqzI3wI.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGHiec-hVyr2k4iOzEQsW1iE.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro ExtraLight Ital"), local("SourceSansPro-ExtraLightIt"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6PwwJPUC4r0o28cUCbhjOjM.woff) format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Light Italic"), local("SourceSansPro-LightIt"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6GGomRtBD2u8FwSY4jjlmeA.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-It"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/M2Jd71oPJhLKp0zdtTvoMzNrcjQuD0pTu1za2FULaMs.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Semibold Italic"), local("SourceSansPro-SemiboldIt"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6PULlOK_XQENnt2ryrY843E.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldIt"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6Nog-We9VNve39Jr4Vs_aDc.woff) format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Source Sans Pro;
  src: local("Source Sans Pro Black Italic"), local("SourceSansPro-BlackIt"), url(https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6ONg1gFYvjbPSGxSBhvPu6E.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Open Sans;
  src: local("Open Sans"), local("OpenSans"), url(https://fonts.gstatic.com/s/opensans/v13/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}